import React from 'react'
import collapse1 from "../../Assets/new_vertical/1.svg";
import collapse2 from "../../Assets/new_vertical/2.svg";
import collapse3 from "../../Assets/new_vertical/3.svg";
import collapse4 from "../../Assets/new_vertical/4.svg";
import collapse5 from "../../Assets/new_vertical/5.svg";
import collapse6 from "../../Assets/new_vertical/6.svg";
import collapse7 from "../../Assets/new_vertical/7.svg";
import collapse8 from "../../Assets/new_vertical/9.svg";
import collapse9 from "../../Assets/new_vertical/9.svg";
import collapse10 from "../../Assets/new_vertical/11.svg";
import collapse11 from "../../Assets/new_vertical/11.svg";
import collapse12 from "../../Assets/new_vertical/12.svg";
import collapse13 from "../../Assets/new_vertical/13.svg";
import collapse14 from "../../Assets/new_vertical/14.svg";
import collapse15 from "../../Assets/new_vertical/15.svg";
import collapse16 from "../../Assets/new_vertical/16.svg";
import collapse17 from "../../Assets/new_vertical/17.svg";
import Slider from 'react-slick';
import { NextArrow, PrewArrow } from './Arrows';

function VerticalCollapse({ selectedCollapse, handleSetCollapse }) {

    const Json = [
        { index: 0, image: collapse1, screen: [{ i: 0, height: 100 }] },
        { index: 1, image: collapse2, screen: [{ direction: 'vertical', width: 100, smallScreen: [{ i: 0, height: 30 }, { i: 1, height: 70 }] }] },
        { index: 2, image: collapse3, screen: [{ direction: 'vertical', width: 100, smallScreen: [{ i: 0, height: 70 }, { i: 1, height: 30 }] }] },
        { index: 3, image: collapse4, screen: [{ direction: 'horizontal', height: 100, smallScreen: [{ i: 0, width: 25 }, { i: 1, width: 75 }] }] },
        { index: 4, image: collapse5, screen: [{ i: 3, scrollTop: 10, text: "" }, { direction: 'horizontal', height: 80, smallScreen: [{ i: 0, width: 25 }, { i: 1, width: 75 }] }, { i: 2, scrollBottom: 10, text: "" }] },
        { index: 5, image: collapse6, screen: [{ direction: 'horizontal', height: 100, smallScreen: [{ i: 0, width: 75 }, { i: 1, width: 25 }] }] },
        { index: 6, image: collapse7, screen: [{ direction: 'horizontal', height: 90, smallScreen: [{ i: 0, width: 75 }, { i: 1, width: 25 }] }, { i: 2, scrollBottom: 10, text: "" }] },
        { index: 7, image: collapse8, screen: [{ i: 3, scrollTop: 10, text: "" }, { direction: 'horizontal', height: 80, smallScreen: [{ i: 1, width: 75 }, { i: 0, width: 25 }] }, { i: 2, scrollBottom: 10, text: "" }] },
        { index: 8, image: collapse9, screen: [{ direction: 'vertical', width: 100, smallScreen: [{ i: 0, height: 50 }, { i: 1, height: 50 }] }] },
        { index: 9, image: collapse10, screen: [{ i: 2, height: 25 }, { direction: 'horizontal', height: 75, smallScreen: [{ i: 0, width: 50 }, { i: 1, width: 50 }] }] },
        { index: 10, image: collapse11, screen: [{ direction: 'horizontal', height: 75, smallScreen: [{ i: 0, width: 50 }, { i: 1, width: 50 }] }, { i: 2, height: 25 }] },
        { index: 11, image: collapse12, screen: [{ direction: 'vertical', width: 100, smallScreen: [{ i: 0, height: 33.33 }, { i: 1, height: 33.33 }, { i: 2, height: 33.34 }] }] },
        { index: 12, image: collapse13, screen: [{ direction: 'vertical', height: 90, width: 100, smallScreen: [{ i: 0, height: 50 }, { i: 1, height: 50 }] }, { i: 2, scrollBottom: 10, text: "" }] },
        { index: 13, image: collapse14, screen: [{ i: 1, height: 45 }, { i: 0, scrollBottom: 10, text: "" }, { i: 2, height: 45 }] },
        { index: 14, image: collapse15, screen: [{ i: 0, scrollTop: 10, text: "" }, { direction: 'vertical', width: 100, height: 90, smallScreen: [{ i: 1, height: 50 }, { i: 2, height: 50 }] }] },
        { index: 15, image: collapse16, screen: [{ i: 3, scrollTop: 10, text: "" }, { direction: 'horizontal', height: 50, smallScreen: [{ i: 0, width: 50 }, { i: 1, width: 50 }] }, { i: 4, height: 30 }, { i: 2, scrollBottom: 10, text: "" }] },
        { index: 16, image: collapse17, screen: [{ i: 3, scrollTop: 10, text: "" }, { i: 4, height: 30 }, { direction: 'horizontal', height: 50, smallScreen: [{ i: 0, width: 50 }, { i: 1, width: 50 }] }, { i: 2, scrollBottom: 10, text: "" }] },
    ];

    const settings = {
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        // prevArrow: <PrewArrow />,
        // nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Slider {...settings}>
                {Json?.map((item, i) => {
                    return (
                        <div style={{ margin: "5px", border: selectedCollapse?.index === item?.index ? "2px solid black" : '' }}>
                            <img
                                src={item?.image}
                                className="card-img-top"
                                height={110}
                                width={110}
                                alt="image not found"
                                onClick={() => handleSetCollapse(item)}
                                style={{ border: selectedCollapse?.index === item?.index ? "2px solid black" : '' }}
                            />
                            <p style={{ margin: 0 }}>{i + 1}</p>
                        </div>
                    )
                })}
            </Slider>
        </>
    )
}

export default VerticalCollapse